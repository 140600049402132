import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Button } from '@ampeersenergy/ampeers-ui-components';

import { Entry } from '../../components';
import GraphqlForm from '../../components/graphql-form';
import { EditContainerProps } from '../../components/createFlow';
import { GraphqlFormField } from '../../components/graphql-form/render';
import {
  formatBoolean,
  formatDate,
  formatDecimal,
} from '../../helpers/formatStrings';
import {
  ReadTariffDocument,
  PriceSheet,
  UpdatePriceSheetDocument,
} from '../../graphql-types';
import { FlexRow, Flex } from '../../components/layout';
import { LOCK_DATE } from '../../helpers/constants';

import { DeletePriceSheetModal } from './deletePriceSheetModal';

interface EditPricesheetProps extends EditContainerProps {}

export default function EditPricesheet({
  onSuccess,
  onAbort,
  variables,
  refetch,
}: EditPricesheetProps) {
  const { tariffId } = useParams<{ tariffId: string }>();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const invalidNames = useMemo(() => {
    if (!variables) return [];

    return variables.tariff.priceSheets
      .filter((p: PriceSheet) => p.name !== variables.priceSheet.name)
      .map((p: PriceSheet) => p.name);
  }, [variables]);

  return (
    <FlexRow style={{ alignItems: 'flex-end', gap: '2px' }}>
      <GraphqlForm
        values={variables.priceSheet}
        mutation="updatePriceSheet"
        readDocument={UpdatePriceSheetDocument}
        startInEdit={false}
        onSuccess={onSuccess}
        onAbort={onAbort}
        refetchQueries={[
          {
            query: ReadTariffDocument,
            variables: { tariffId: [tariffId] },
          },
        ]}
        variables={{
          tariffId,
          priceSheetId: variables.priceSheet.id,
        }}
        validation={{
          name: yup
            .mixed()
            .notOneOf(
              invalidNames,
              'Es gibt bereits ein Preisblatt mit diesem Namen',
            )
            .required(),
        }}
      >
        <FlexRow>
          <Flex>
            <GraphqlFormField name="name" autoFocus />
            <Entry title="Grundpreis (Netto)" unit="€/Monat">
              {formatDecimal(variables.priceSheet.basicPrice)}
            </Entry>
            {variables.priceSheet.energyPrice === undefined ||
            variables.priceSheet.energyPrice === null ? (
              <>
                <Entry title="Arbeitspreis Lokalstrom (Netto)" unit="Cent/kWh">
                  {formatDecimal(variables.priceSheet.energyPriceLocal)}
                </Entry>
                <Entry title="Arbeitspreis Reststrom (Netto)" unit="Cent/kWh">
                  {formatDecimal(variables.priceSheet.energyPriceResidual)}
                </Entry>
              </>
            ) : (
              <Entry title="Arbeitspreis gemischt (Netto)" unit="Cent/kWh">
                {formatDecimal(variables.priceSheet.energyPrice)}
              </Entry>
            )}
            <Entry title="Beginn der Preisblattgültigkeit">
              {formatDate(variables.priceSheet.startDate)}
            </Entry>
            <Entry title="Bestehende Preisgarantien ignorieren">
              {formatBoolean(variables.priceSheet.ignorePriceGuarantee)}
            </Entry>
          </Flex>
        </FlexRow>
      </GraphqlForm>
      {variables.priceSheet?.createdAt &&
        new Date(variables.priceSheet.createdAt) > LOCK_DATE && (
          <Button
            data-testid="graphql-form-delete"
            secondary
            onClick={() => setDeleteModalIsOpen(true)}
          >
            Löschen
          </Button>
        )}

      <DeletePriceSheetModal
        priceSheet={variables.priceSheet}
        isOpen={deleteModalIsOpen}
        onClose={() => {
          refetch?.();
          setDeleteModalIsOpen(false);
        }}
      />
    </FlexRow>
  );
}
