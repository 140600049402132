import React, { useState } from 'react';
import { Button, Icons, Modal } from '@ampeersenergy/ampeers-ui-components';
import styled, { useTheme } from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';

import {
  CheckDeletePriceSheetDocument,
  CheckDeletePriceSheetQuery,
  CheckDeletePriceSheetQueryVariables,
  DeletePriceSheetDocument,
  DeletePriceSheetMutation,
  DeletePriceSheetMutationVariables,
  PriceSheet,
} from '../../graphql-types';
import { ErrorMsg } from '../../components';

const ButtonGroup = styled.div`
  justify-content: flex-end;
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const Container = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
`;

enum DeleteState {
  inital = 'inital',
  successfull = 'successfull',
  error = 'error',
}

export const DeletePriceSheetModal = ({
  isOpen,
  onClose,
  priceSheet,
}: {
  priceSheet: PriceSheet;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [deletePriceSheet] = useMutation<
    DeletePriceSheetMutation,
    DeletePriceSheetMutationVariables
  >(DeletePriceSheetDocument);
  const [checkDeletePriceSheet] = useLazyQuery<
    CheckDeletePriceSheetQuery,
    CheckDeletePriceSheetQueryVariables
  >(CheckDeletePriceSheetDocument);
  const theme = useTheme();
  const [state, setState] = useState(DeleteState.inital);
  const [errorMsg, setErrorMsg] = useState<string>();
  const deleteSheet = async () => {
    const checkResponse = await checkDeletePriceSheet({
      variables: {
        id: priceSheet.id,
      },
    });
    const checkSuccess = checkResponse.data?.checkDeletePriceSheet?.success;
    if (!checkSuccess || checkResponse?.error) {
      setErrorMsg(
        checkResponse?.error
          ? checkResponse?.error?.message
          : 'Dieses Preisblatt wurde bereits für Abrechnungen verwendet. Es kann daher nicht gelöscht werden.',
      );
      setState(DeleteState.error);
      return;
    }
    try {
      const deleteResponse = await deletePriceSheet({
        variables: {
          id: priceSheet.id,
        },
      });
      if (!deleteResponse?.data?.deletePriceSheet?.success) {
        setState(DeleteState.error);
        setErrorMsg(
          'Dieses Preisblatt wurde bereits für Abrechnungen verwendet. Es kann daher nicht gelöscht werden.',
        );
        return;
      }
      setState(DeleteState.successfull);
    } catch (error) {
      setState(DeleteState.error);
      setErrorMsg(JSON.stringify(error));
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
        setState(DeleteState.inital);
      }}
      contentLabel="Delete-PriceSheet-Modal"
      title="Preisblatt löschen"
      maxWidth="sm"
      minWidth={500}
    >
      <Container>
        {state === DeleteState.inital && (
          <span>
            Möchten Sie wirklich das Preisblatt{' '}
            <b>&quot;{priceSheet.name}&quot;</b> löschen? löschen? Das
            Preisblatt wird durch das zuvor gültige ersetzt.
          </span>
        )}
        {state === DeleteState.successfull && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Icons.Checkmark size={45} color={theme.palette.success.color} />
            <span>Das Preisblatt wurde erfolgreich gelöscht</span>
          </div>
        )}
        {state === DeleteState.error && (
          <ErrorMsg
            title="Preisblatt kann nicht gelöscht werden"
            message={errorMsg}
            retryable={false}
          />
        )}
      </Container>

      <ButtonGroup>
        <Button onClick={onClose} secondary>
          {state === DeleteState.inital ? 'Abbrechen' : 'Schließen'}
        </Button>
        {state === DeleteState.inital && (
          <Button
            data-testid="modal-delete-sheet"
            onClick={async () => deleteSheet()}
            customTheme={{
              primaryColor: theme.palette.error.color,
              secondaryColor: theme.palette.error.background,
            }}
          >
            Löschen
          </Button>
        )}
      </ButtonGroup>
    </Modal>
  );
};
