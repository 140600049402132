import * as React from 'react';
import { useState } from 'react';
import { DateTime } from 'luxon';
import 'styled-components/macro';
import * as yup from 'yup';
import { useApolloClient } from '@apollo/client';
import { WarningAlert } from '@ampeersenergy/ampeers-ui-components';

import GraphqlForm from '../../../components/graphql-form';
import {
  AccountingsInTimespanDocument,
  AccountingsInTimespanQuery,
  AccountingType,
  AccountingWorkflowPayload,
  InvoiceCycle,
} from '../../../graphql-types';

import {
  CreateAccountingWorkflowFormProps,
  FormVariablesAccountingWorkflow,
} from './types';

const toUTC = (date: Date) =>
  DateTime.fromJSDate(date).setZone('utc').toFormat('yyyy-MM-dd');

export default function CreateAccountingWorkflowForm({
  onSuccess,
  onAbort,
  mutation,
  readDocumentsFields,
  formVariables,
  innerForm,
  innerFormType,
}: CreateAccountingWorkflowFormProps) {
  const client = useApolloClient();

  const {
    name,
    accountingTypes = [AccountingType.StandardAccounting],
    workflowType,
    contractToAccount,
    paymentPeriodStartAt,
    paymentPeriodEndAt,
    settlementDay,
    settlementMonth,
    invoiceCycle,
    quarterlyDeduction,
    contractStartDate,
    plantsToAccount = [],
  } = formVariables;

  const [showNotAccountableError, setShowNotAccountableError] = useState(false);
  const invoiceCycleValue = invoiceCycle ?? InvoiceCycle.Yearly;
  const defaultValues: FormVariablesAccountingWorkflow = {
    workflowType,
    name,
    ...(accountingTypes.length === 1 && {
      accountingType: accountingTypes[0],
    }),
    contractToAccount,
    // Conversion toUtc: despite the values come as Date, we work inside the form with strings because that's how graphql expect the values
    ...(paymentPeriodStartAt && {
      paymentPeriodStartAt: toUTC(paymentPeriodStartAt),
    }),
    ...(paymentPeriodEndAt && {
      paymentPeriodEndAt: toUTC(paymentPeriodEndAt),
    }),
    ...((invoiceCycleValue === InvoiceCycle.Yearly ||
      innerFormType === 'dashboard') && {
      settlementDay,
      settlementMonth,
    }),
    invoiceCycle: invoiceCycleValue,
    plantsToAccount,
    quarterlyDeduction,
    contractStartDate,
  };

  const validateIfPlantsAreAccountable = async (
    values: Partial<AccountingWorkflowPayload>,
  ) => {
    setShowNotAccountableError(false);
    const accountings = await client.mutate<AccountingsInTimespanQuery>({
      mutation: AccountingsInTimespanDocument,
      variables: {
        contractIds: values.contractToAccount
          ? [values.contractToAccount.toString()]
          : undefined,
        plantIds: values.plantsToAccount,
        startDate: values.paymentPeriodStartAt,
        endDate: values.paymentPeriodEndAt,
      },
    });

    if (!accountings) {
      return Promise.reject(
        new Error('Fehler beim Überprüfen der Abrechnungen'),
      );
    }

    if (!accountings.data?.accountingsInTimespan?.length) {
      return Promise.resolve(values);
    }

    setShowNotAccountableError(true);
    return Promise.resolve(null);
  };

  return (
    <GraphqlForm<Partial<AccountingWorkflowPayload>>
      mutation={mutation}
      startInEdit
      onSuccess={(result, add) => {
        if (onSuccess) {
          onSuccess(result, add);
        }
      }}
      onBeforeSubmit={validateIfPlantsAreAccountable}
      onAbort={onAbort}
      readDocumentFields={readDocumentsFields}
      formVariables={formVariables}
      defaultValues={defaultValues}
      validation={{
        settlementDay: yup.number().required(),
        settlementMonth: yup.number().required(),
        name: yup.string().required(),
        plantsToAccount: yup
          .array()
          .of(yup.string())
          .when('workflowType', {
            is: 'multiple',
            then: yup.array().of(yup.string()).required(),
          }),
        accountingType: yup
          .string()
          .oneOf([
            'standardAccounting',
            'closingAccountingMove',
            'closingAccountingThird',
            'betweenAccounting',
            'correctionAccounting',
          ])
          .required(),
      }}
      labels={{
        submit: 'Erstellen',
      }}
    >
      {innerForm}
      {showNotAccountableError && (
        <WarningAlert>
          {workflowType === 'single' ? (
            <span>
              Es besteht bereits eine Abrechnung für diesen Vertrag.
              <br />
              Setze diese Abrechnungen fort oder lösche sie, um neu zu beginnen.
            </span>
          ) : (
            <span>
              Es besteht bereits mindestens eine Abrechnung für diese
              Kundenanlage.
              <br />
              Setze diese Abrechnungen fort oder lösche sie, um neu zu beginnen.
            </span>
          )}
        </WarningAlert>
      )}
    </GraphqlForm>
  );
}
