import { Metering } from '../graphql-types';

export const METER_READING_START_DATE = '2016-12-31T23:00:00.000Z';
export const METER_READING_END_DATE = '9999-12-31T00:00:00.000Z';

export const METER_OBIS_CHANNEL_SLP_BEZUG = '1-1:1.8.0';
export const METER_OBIS_CHANNEL_SLP_EINSPEISUNG = '1-1:2.8.0';

export const METER_OBIS_CHANNEL_RLM_BEZUG = '1-1:1.29.0';
export const METER_OBIS_CHANNEL_RLM_EINSPEISUNG = '1-1:2.29.0';

export const METER_TYPES_WITH_MULTIPLE_OBIS_CHANNELS = [
  'MSO-H',
  'MSO-E',
  'MSO-K',
];

export const METERING_OBIS_MAP: Record<Metering, string[]> = {
  [Metering.Slp]: [
    METER_OBIS_CHANNEL_SLP_BEZUG,
    METER_OBIS_CHANNEL_SLP_EINSPEISUNG,
  ],
  [Metering.Rlm]: [
    METER_OBIS_CHANNEL_RLM_BEZUG,
    METER_OBIS_CHANNEL_RLM_EINSPEISUNG,
  ],
};

export const OBIS_CHANNEL_TYPE_TEXT_MAP: Record<string, string> = {
  [METER_OBIS_CHANNEL_RLM_EINSPEISUNG]: 'Einspeisung',
  [METER_OBIS_CHANNEL_SLP_EINSPEISUNG]: 'Einspeisung',
  [METER_OBIS_CHANNEL_RLM_BEZUG]: 'Bezug',
  [METER_OBIS_CHANNEL_SLP_BEZUG]: 'Bezug',
};

export const LOCK_DATE = new Date('2024-08-22T14:48:56.403Z');
